<script>
export default {
  name: "SoporteVideos",
  components: {},
  mounted() {
    this.$store.getters
      .fetchGet({ path: "CapacitacionCRUD/ListCapacitacion/all" })
      .then((response) => response.json())
      .then((result) => {
        this.videos = result;
        if (result.length > 0) {
          this.selectedVideo = { ...result[0], index: 0 };
          this.validateVideoURL(this.selectedVideo.url);
        }
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  data() {
    return {
      videos: [],
      selectedVideo: null,
      isVideoURLValid: false,
    };
  },
  methods: {
    clickHandle(video, index) {
      this.selectedVideo = video;
      this.selectedVideo.index = index;
      this.validateVideoURL(video.url);
    },
    nextVideo() {
      if (this.selectedVideo.index < this.videos.length - 1) {
        this.clickHandle(
          this.videos[this.selectedVideo.index + 1],
          this.selectedVideo.index + 1
        );
      }
    },
    previousVideo() {
      if (this.selectedVideo.index > 0) {
        this.clickHandle(
          this.videos[this.selectedVideo.index - 1],
          this.selectedVideo.index - 1
        );
      }
    },
    async validateVideoURL(videoURL) {
      try {
        await fetch(videoURL, { method: "HEAD", mode: "no-cors" });
        this.isVideoURLValid = true;
      } catch (error) {
        console.error("Error al validar URL:", error);
        this.isVideoURLValid = false;
      }
    },
  },
  computed: {
    filteredVideos() {
      return this.videos.slice(0, 3); // Retorna solo los primeros 5 videos
    },
    nextVideoTitle() {
      if (this.selectedVideo.index < this.videos.length - 1) {
        return this.videos[this.selectedVideo.index + 1].titulo;
      }
      return "";
    },
    previousVideoTitle() {
      if (this.selectedVideo.index > 0) {
        return this.videos[this.selectedVideo.index - 1].titulo;
      }
      return "";
    },
  },
};
</script>

<template>
  <b-container fluid>
    <b-row class="justify-content-lg-center">
      <b-col>
        <card class="py-4 px-4">
          <div>
            <!-- main content -->
            <div class="d-flex flex-column">
              <div
                class="d-flex justify-content-between align-items-center w-100"
              >
                <b-button v-b-toggle.sidebar-variant variant="success"
                  ><i class="fas fa-list-ul"></i> Ver Todos
                  <i class="fas fa-chevron-right"></i
                ></b-button>
                <b-tabs content-class="">
                  <!-- lista de videos hasta 3 -->
                  <b-tab
                    v-for="(video, index) in filteredVideos"
                    :key="video.id"
                    @click="clickHandle(video, index)"
                    :active="index === selectedVideo?.index"
                  >
                    <template v-slot:title>
                      <div
                        class="d-flex align-items-center"
                        v-b-tooltip.hover
                        :title="video.titulo"
                      >
                        <i
                          class="fas fa-play mr-1"
                          v-if="index === selectedVideo?.index"
                        ></i>
                        <small
                          class="bg-white mr-1"
                          style="
                            border-radius: 50%;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                          "
                          v-else
                          >{{ index + 1 }}</small
                        >
                        <!-- <i class="fas fa-video" v-else></i> -->
                        {{
                          video.titulo.length > 25
                            ? video.titulo.substring(0, 22) + "..."
                            : video.titulo
                        }}
                      </div>
                    </template>
                  </b-tab>
                  <b-tab
                    v-if="selectedVideo?.index > 2"
                    :active="selectedVideo?.index"
                  >
                    <template v-slot:title>
                      <div
                        class="d-flex align-items-center"
                        v-b-tooltip.hover
                        :title="selectedVideo.titulo"
                      >
                        <i class="fas fa-play mr-1"></i>
                        {{
                          selectedVideo.titulo.length > 25
                            ? selectedVideo.titulo.substring(0, 22) + "..."
                            : selectedVideo.titulo
                        }}

                        <small
                          class="bg-warning ml-1"
                          style="
                            border-radius: 50%;
                            width: 20px;
                            height: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: center;
                          "
                          >{{ selectedVideo?.index + 1 }}</small
                        >
                      </div>
                    </template>
                  </b-tab>
                </b-tabs>
              </div>
              <div class="d-flex flex-column w-100 align-items-center">
                <div class="w-75" v-if="isVideoURLValid">
                  <b-embed
                    class="rounded"
                    type="iframe"
                    aspect="16by9"
                    :src="selectedVideo.url"
                    allowfullscreen
                  ></b-embed>
                  <div>
                    <div
                      class="mt-4 d-flex justify-content-between align-items-center"
                    >
                      <h3 class="mb-3">
                        <span class="p-3 border rounded-circle"
                          ><i class="fas fa-video text-warning"
                        /></span>
                        {{
                          selectedVideo.titulo.length > 30
                            ? selectedVideo.titulo.substring(0, 30) + "..."
                            : selectedVideo.titulo
                        }}
                        <small
                          class="ml-2"
                          style="font-size: 15px; opacity: 75%"
                          >{{ selectedVideo?.index + 1 }}/{{
                            videos.length
                          }}</small
                        >
                      </h3>
                      <div>
                        <!-- back -->
                        <Transition name="fade">
                          <span
                            v-b-tooltip.hover
                            :title="previousVideoTitle"
                            v-if="selectedVideo?.index > 0"
                            class="rounded py-3 px-4 mr-2 border pointer"
                            @click="previousVideo"
                            ><i
                              class="fas fa-step-backward zoom"
                              style="font-size: 1vw"
                          /></span>
                        </Transition>
                        <!-- next -->
                        <Transition name="fade">
                          <span
                            v-b-tooltip.hover
                            :title="nextVideoTitle"
                            v-if="selectedVideo?.index != videos.length - 1"
                            class="rounded py-3 px-4 border pointer"
                            @click="nextVideo"
                            ><i
                              class="fas fa-step-forward zoom"
                              style="font-size: 1vw"
                          /></span>
                        </Transition>
                      </div>
                    </div>
                    <!-- descripcion -->
                    <div class="mt-4 w-100">
                      <span
                        class="py-2 font-weight-bold border rounded-top px-2"
                        style="font-size: medium"
                      >
                        Descripción
                        <i class="fas fa-info-circle text-primary" />
                      </span>
                      <hr class="mt-2" />
                      <p class="pl-3" style="opacity: 75%">
                        {{ selectedVideo.descripcion }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="text-center" v-else>
                  <p class="text-danger">Error al cargar el video.</p>
                  <i
                    class="fas fa-video-slash"
                    style="opacity: 75%; font-size: 2rem"
                  />
                </div>
              </div>
            </div>
            <!-- sidebar videos soporte -->
            <b-sidebar
              id="sidebar-variant"
              bg-variant="dark"
              text-variant="light"
              backdrop
              shadow
            >
              <template v-slot:title>
                <small
                  >Videos de soporte
                  <i
                    class="fas fa-question-circle text-warning"
                    style="opacity: 75%"
                  ></i
                ></small>
              </template>
              <div class="py-2">
                <!-- lista completa de videos -->
                <ul class="p-4">
                  <li
                    :class="`pointer p-2 mr-2 rounded ${
                      index === selectedVideo?.index ? 'bg-success' : ''
                    }`"
                    v-for="(video, index) in videos"
                    :key="video.id"
                    @click="clickHandle(video, index)"
                  >
                    <div class="d-flex">
                      <i
                        v-if="index === selectedVideo?.index"
                        :class="`fas fa-video ${
                          index === selectedVideo?.index
                            ? 'text-white'
                            : 'text-gray'
                        }`"
                        style="font-size: large"
                      ></i>
                      <small class="bg-gray circle-badge" v-else>{{
                        index + 1
                      }}</small>
                      <p
                        :class="`ml-2 pt-1 font-weight-bold ${
                          index === selectedVideo?.index
                            ? 'text-white'
                            : 'text-gray'
                        }`"
                      >
                        {{
                          video.titulo.length > 25
                            ? video.titulo.substring(0, 25) + "..."
                            : video.titulo
                        }}
                      </p>
                    </div>
                    <p
                      v-if="index === selectedVideo?.index"
                      class="ml-2 text-white"
                      style="opacity: 75%"
                    >
                      <i class="fas fa-info-circle" style="opacity: 75%"></i>
                      {{
                        video.descripcion.length > 60
                          ? video.descripcion.substring(0, 60) + "..."
                          : video.descripcion
                      }}
                    </p>
                  </li>
                </ul>
              </div>
            </b-sidebar>
          </div>
        </card>
      </b-col>
    </b-row>
  </b-container>
</template>

<style scoped>
/* .active {
  background-color: #059668;
} */
.active {
  background-color: rgba(5, 150, 104, 0.8);
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.circle-badge {
  border-radius: 50%;
  /* Esto hace que sea un círculo */
  width: 24px;
  /* Define el ancho. Puedes ajustar según necesidades */
  height: 24px;
  /* Define el alto. Debe ser igual al ancho para un círculo perfecto */
  display: flex;
  /* Centrar el contenido en horizontal */
  justify-content: center;
  /* Centrar el contenido en horizontal */
  align-items: center;
  /* Centrar el contenido en vertical */
  text-align: center;
}
</style>
